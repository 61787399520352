import defaultColors from "../util/default-colors.json"
import darkColors from "../util/dark-theme-colors.json"
import { lightness } from "@theme-ui/color"
// TODO cleanp these modes styling key-value pairs
const theme = {
  colors: {
    ...defaultColors,
    text: "#000",
    background: "#fff",
    primary: "#5C2941",
    secondary: "black",
    navColor: "black",
    navActive: "#fffff",
    logoColor: "black",
    navIcons: "black",
    accent: "#fff",
    muted: "black",
    lines: "black",
    linesBackground: "white",
    tagline: "#949494d9",
    cardBg: "#fff",
    borderColor: "#540229",
    labelText: "#777",
    inputBorder: "black",
    inputBackground: "#fff",
    buttonText: "white",
    navBackgroundColor: "#fff",
    buttonBorderColor: "1px solid #ffffff !important",
    contactFormColor: "#fff",
    contactShadowBox: "0px 8px 8px #c3c3c3a6",
    socialIcons: lightness("black"),
    socialIconsHover: lightness("siteColor", 0.3),
    buttonColor: "#D69929",
    buttonHoverBg: lightness("siteColor", 0.4),
    buttonHoverColor: lightness("siteColor", 0.6),
    modes: {
      dark: {
        text: "#f5f5f5",
        background: "#111",
        navBackgroundColor: "#252525",
        primary: "#252525",
        secondary: "black",
        navColor: "#ffffff",
        navActive: "black",
        logoColor: "#ffffff",
        navIcons: "#ffffff",
        accent: "#5C2941",
        muted: "#d6d6d6",
        lines: "white",
        linesBackground: "#111",
        tagline: "#888",
        cardBg: "white",
        borderColor: "#888",
        labelText: "#777",
        inputBorder: "#777",
        inputBackground: "#fff",
        postLink: "#ffffff",
        buttonText: "black",
        buttonBorderColor: "1px solid #e2e2e2",
        contactFormColor: "#111",
        contactShadowBox: "",
        socialIcons: lightness("black", 0.5),
        socialIconsHover: lightness("siteColor", 1.0),
        buttonColor: lightness("siteColor", 0.9),
        buttonHoverBg: lightness("siteColor", 0.3),
        buttonHoverColor: lightness("siteColor", 0.8),
        ...darkColors,
      },
    },
  },
  links: {
    postLink: {
      color: "black",
      "&:hover": {
        color: "#9CB7D4",
      },
    },
    date: {
      color: "secondary",
    },
  },
  variants: {
    button: {
      bg: "buttonColor",
      color: "buttonText",
      "&:hover": {
        bg: "buttonHoverColor",
        color: "black",
        border: "buttonBorderColor"
      },
    },
    socialIcons: {
      a: {
        color: "socialIcons",
        ":hover": {
          color: "socialIconsHover",
        },
      },
    },
    tagline: {
      color: "tagline",
    },
    navColor: {
      bg: "navBackgroundColor",
      color: "navColor",
      ":hover": {
        color: "navColor",
      },
    },
    navBgColor: {
      bg: "navBackgroundColor",
    },
    navLogoColor: {
      color: "logoColor"
    },
    navIcons: {
      color: "navIcons",
      ":hover": {
        color: "#9CB7D4",
      },
    },
    contactFormColor: {
      bg: "contactFormColor",
    },
    linesColor: {
      borderBottom: "1px solid #696969",
      color: "lines",
    },
    linesBackground: {
      bg: "linesBackground",
    }
  },
}

export default theme
